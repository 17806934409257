import React, { useState } from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import './App.css';
import logo from './logo.svg';
import AccountDashboard from './AccountDashboard';
import SignupPage from './SignupPage';
import { createRoot } from 'react-dom/client';

import usersData from './data/users.json';



function Login() {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  function handleUsernameChange(event) {
    setUsername(event.target.value);
  }

  function handlePasswordChange(event) {
    setPassword(event.target.value);
  }

  function handleSubmit(event) {
    event.preventDefault();
  
    const user = usersData.find(u => u.username === username && u.password === password);
  
    if (user) {
      // handle successful login
      console.log("Logged in as:", user.username);
      root.render(<AccountDashboard />);
    } else {
      // handle failed login
      setErrorMessage("Invalid username or password");
    }
  }

function StartSignup(event) {
  root.render(<SignupPage />);
}

 return (
   <div className="SmartSplit">
     <header className="App-header">
       <img src={logo} className="App-logo" />
       <h2 className="motto">Split Smarter.. <br></br>not Harder</h2>
       <form onSubmit={handleSubmit} className="login-form">
         <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
           <label style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
             Username:&nbsp;&nbsp;
             <input type="text" value={username} onChange={handleUsernameChange} className="login-form-input" />
           </label>
           
         </div>
         <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", marginTop: "10px" }}>
           <label style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
             Password:&nbsp;&nbsp;
             <input type="password" value={password} onChange={handlePasswordChange} className="login-form-input" />
           </label>
         </div>
         {errorMessage && <p style={{ color: 'red' }}>{errorMessage}</p>}
        <button type="submit" className="button button-blue">Log in</button>&nbsp;&nbsp;
        <button type="button" className="button button-green" onClick={(e) => {e.preventDefault();StartSignup();}}>Sign up</button>
       </form>        
     </header>
   </div>
 );
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Login />
  </React.StrictMode>
);

