import React from 'react';
import ReactDOM from 'react-dom/client'

import './SignupPage.css';
import './index.css';
import './App.css';
import { renderPaymentSection, CreateAccount, ReturnHome } from './DashboardComponents.js';
import AccountDashboard from './AccountDashboard';

function linkPaymentMethod(paymentMethod) {
  console.log(`Linking payment method: `);
  // code to handle linking the payment method goes here
}

function LoadDashboard() {
  // Code to load the AccountDashboard component goes here
  // For example:
  const root = ReactDOM.createRoot(document.getElementById('root'));
  root.render(
    <AccountDashboard />
  );
}

function AccountSetupForm() {
  return (
    <div className="account-setup App-header">
      <h1>Account Setup</h1>
      <section className="section">
        <h2 className="subtitle">Account Information</h2>
        <form>
          <label style={{ textAlign: 'right' }}>First Name:</label>
          <input className="login-form-input" type="text" name="firstName" required />
          <br />
          <label style={{ textAlign: 'right' }}>Last Name:</label>
          <input className="login-form-input" type="text" name="lastName" required />
          <br />
          <label style={{ textAlign: 'right' }}>Email:</label>
          <input className="login-form-input" type="email" name="email" required />
          <br />
          <label style={{ textAlign: 'right' }}>Password:</label>
          <input className="login-form-input" type="password" name="password" required />
        </form>
      </section>
      <details>
        <summary className="subtitle">Payment Information</summary>
        <section className="section">
          <div>
            <button className="payment-button" onClick={() => linkPaymentMethod('Venmo')}>
              Venmo
            </button>
            <button className="payment-button" onClick={() => linkPaymentMethod('Paypal')}>
              Paypal
            </button>
            <button className="payment-button" onClick={() => linkPaymentMethod('Cash App')}>
              Cash App
            </button>
          </div>
        </section>
      </details>
      
      <CreateAccount />
      
      <ReturnHome />
    </div>
  );
}

export default AccountSetupForm;