// AccountActivity component
export function AccountActivity() {
  return (
    <section>
      <img src="https://www.mymoneyblog.com/images/0807/exp1.jpg" />
    </section>
  );
}
// Contract Management components
export function ContractManagement() {
return (
    <section>
    <table className="account-dashboard-panel">
        <tbody className="account-dashboard-body">
        <tr className="account-dashboard-row">
            <td className="account-dashboard-cell">
            <button type="button" className="button button-blue">
                Create Contract
            </button>
            </td>
            <td className="account-dashboard-cell">
            <button type="button" className="button button-grey">
                Manage Contracts
            </button>
            </td>
            <td className="account-dashboard-cell">
            <button type="button" className="button button-green">
                Use Contract
            </button>
            </td>
        </tr>
        </tbody>
    </table>
    </section>
);
}
// Account Management components
export function AccountManagement() {
return (
    <section>
    <table className="account-dashboard-panel">
        <tbody className="account-dashboard-body">
        <tr className="account-dashboard-row">
            <td className="account-dashboard-cell">
            <button type="button" className="button button-blue">
                Edit Profile
            </button>
            </td>
            <td className="account-dashboard-cell">
            <button type="button" className="button button-grey">
                Update Information
            </button>
            </td>
            <td className="account-dashboard-cell">
            <button type="button" className="button button-green">
                Link Payment
            </button>
            </td>
        </tr>
        </tbody>
    </table>
    </section>
);
}
// Function for back to home button
export function ReturnHome() {
return (
    <div className="account-dashboard-row">
    <div className="account-dashboard-cell">
        <a href="/" className="button button-red">
        Back
        </a>
    </div>
    </div>
);
}

// Function for back to home button
export function CreateAccount() {
  return (
      <div className="account-dashboard-row">
      <div className="account-dashboard-cell">
          <a href="/" className="button button-green">
          Sign up
          </a>
      </div>
      </div>
  );
  }
  


export function renderPaymentSection() {
    function linkPaymentMethod(paymentMethod) {
        console.log(`Linking payment method: `);
        // code to handle linking the payment method goes here
      }
      

    return (
      <section className='section'>
        <h2 className="subtitle">Payment Information</h2>
        <div>
          <button className="payment-button" onClick={() => linkPaymentMethod('Venmo')}>
            Venmo
          </button>
          <button className="payment-button" onClick={() => linkPaymentMethod('Paypal')}>
            Paypal
          </button>
          <button className="payment-button" onClick={() => linkPaymentMethod('Cash App')}>
            Cash App
          </button>
        </div>
      </section>
    );
  }