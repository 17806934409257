import React from 'react';
import ReactDOM from 'react-dom/client'

import './AccountDashboard.css';
import './index.css';
import Dashboard from './SignupPage';
import { AccountActivity, ContractManagement, AccountManagement, ReturnHome } from './DashboardComponents.js';

// Account Dashboard components
function AccountDashboard() {
  return (
    <div className="App-header">
      <div className="account-dashboard-box">
        <h1>Account Dashboard</h1>
        <details>
          <summary className="subtitle">Account Activity
          </summary>
          <AccountActivity />
        </details>
        <details>
          <summary className="subtitle">Contract Management
          </summary>
          <ContractManagement />
        </details>
        <details>
          <summary className="subtitle">Account Management
          </summary>
          <AccountManagement />
        </details>
        <ReturnHome />
      </div>
    </div>
  );
}

export default AccountDashboard;